import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faChevronRight,
  faPlay,
} from "@fortawesome/free-solid-svg-icons"

export const bannerContent = {
  title:
    "Whatever your subscription business, rely on Billsby for easy and accurate recurring billing.",
  description: [
    {
      text:
        "The most powerful, customizable and easy to integrate subscription billing software used by hundreds of companies worldwide to simplify revenue operations.",
    },
  ],
  // withPromotion: true,
  // promotionBadge: "New",
  // promotionTitle: "We’ve lowered our transaction fee to 0.4% for all customers",
  // promotionURL: "/compared",
  // promotionURLText: (
  //   <>
  //     SEE HOW WE COMPARE{" "}
  //     <FontAwesomeIcon icon={faChevronRight} className="icon-check" />{" "}
  //   </>
  // ),
  textColor: "#fff",
  backgroundColor: "#243F5C",
  // backgroundImage: require('../images/home-hero.png'),
  // image: 'home-hero.png',
  svg: true,
  image: require("../images/home-hero.svg"),
  imageName: "billsby banner image",
  imagePosition: "right",
  sectionHomeBanner: true,
  hasBg: true,
  isBanner: true,
  buttons: [
    {
      url: "https://app.billsby.com/registration",
      buttonText: "Sign up today",
      buttonColor: "orange",
      isExternal: true,
    },
    {
      scheduleButton: true,
    },
  ],
}

export const comparisonTableContent = {
  title: "How we stack up",
  description:
    "With incredible features included for everyone – and no features you need for your business to succeed locked behind expensive plans – we’ve made sure that Billsby is the most competitive recurring payments software around.",
  heading: [
    {
      logo: "billsby_logo_blue.png",
      title: "Billsby",
    },
    {
      logo: require("../images/chargebee.svg"),
      title: "Chargebee",
      svgLogo: true,
    },
    {
      logo: require("../images/chargify.svg"),
      title: "Chargify",
      svgLogo: true,
    },
    {
      logo: require("../images/recurly.png"),
      title: "Recurly",
      svgLogo: true,
    },
    {
      logo: require("../images/stripe-billing.png"),
      title: "Stripe Billing",
      svgLogo: true,
    },
  ],
  body: [
    {
      title: "Monthly fee",
      content: [
        {
          itemContent: "$35",
        },
        {
          itemContent: "From $99",
        },
        {
          itemContent: "From $599",
        },
        {
          itemContent: "From $149",
        },
        {
          itemContent: "$0",
        },
      ],
    },
    {
      title: "Overage transaction fee",
      content: [
        {
          itemContent: "0.4%",
        },
        {
          itemContent: "Up to 0.5%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 0.5%",
        },
      ],
    },
    {
      title: "Usage counters",
      content: [
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
      ],
    },
    {
      title: "Feature tags",
      content: [
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
      ],
    },
    {
      title: "Advanced retention tools",
      content: [
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
      ],
    },
    {
      title: "Plan actions",
      subtitle: "coming soon",
      content: [
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
      ],
    },
    {
      title: "Value scoring",
      subtitle: "coming soon",
      content: [
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
      ],
    },
  ],
  buttons: [
    {
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      text: "Sign up today",
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

export const trialContent = {
  title: "Get started with Billsby today",
  description:
    <>You can sign up to Billsby for as little as $35 per month, including $15,000 per month in transaction value. <br /> The best way to get to know Billsby is to try it for yourself.</>,
  svg: true,
  image: require("../images/free-trial.svg"),
  imageName: "free-trial image",
  buttons: [
    {
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      buttonText: "Get started with your account today",
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

export const planActionContent = {
  title: "Power your subscriptions with Zapier.",
  description:
    "Once customers sign-up and as their subscription changes, use Zapier to complete follow up tasks across all of the other tools and services you use to run your business.",
  svg: true,
  image: require("../images/plan-action.svg"),
  imageName: "plan action image",
  cards: [
    {
      image: "plan-action-1.png",
      text: "Add the new customer to your CRM system",
      svgLogo: true,
      logo: require("../images/pipedrive.svg"),
      logoName: "pipedrive",
    },
    {
      image: "plan-action-2.png",
      text: "Subscribe them to the correct mailing list",
      logo: "mailchimp.png",
      logoName: "mailchimp",
    },
    {
      image: "plan-action-3.png",
      text: "Post out their welcome pack",
      // logo: "shipwire.png",
      svgLogo: true,
      logo: require("../images/shipwire.svg"),
      logoName: "shipwire",
    },
  ],
  url: "/product/integrations/zapier",
  buttonColor: "blue",
  buttonText: "Learn about Zapier",
  comingSoonText: "",
}

export const customerJourneyContent = {
  title: "Deliver a great experience throughout the customer journey.",
  list: [
    {
      title: "Get customers signed up",
      description: (
        <>
          Our{" "}
          <Link to="/product/subscribers/checkout-and-payments">
            drop-in checkout
          </Link>{" "}
          helps customers to sign up for your product quickly and easily, with
          low development effort.
        </>
      ),
    },
    {
      title: "Self-service account management",
      description: (
        <>
          Customers can manage their own account with our{" "}
          <Link to="/product/subscribers/account-management">
            drop-in account management tools
          </Link>{" "}
          - reducing service costs.
        </>
      ),
    },
    {
      title: "Keep customers from leaving",
      description: (
        <>
          When your customers think about leaving, our{" "}
          <Link to="/product/subscribers/dunning-retention">
            retention and promotion tools
          </Link>{" "}
          can stop them and help reduce churn.
        </>
      ),
    },
  ],
  svg: true,
  image: require("../images/customer-journey2.svg"),
  imageName: "customer journey",
}

export const developersContent = {
  title:
    "Developers love Billsby because it's easier to integrate and maintain.",
  description: [
    {
      text:
        "From the start, we've built Billsby to balance the commercial desires of businesses to introduce new offers and plans frequently with developers' needs to not constantly have to update their product to support these business changes. From feature tags to real-time usage counters, Billsby enables flexibility you won't find elsewhere.",
    },
  ],
  buttons: [
    {
      url: "/product/developers",
      buttonColor: "white",
      buttonText: "Discover Billsby for developers",
    },
  ],
  sectionDevelopers: true,
  // image: require('../images/home-developers.png'),
  image: "home-developers.png",
  // srcSet: 'home-developers@2x.png 2x',
  imageName: "developers",
  backgroundColor: "#243F5C",
  textColor: "#fff",
  imagePosition: "right",
}

export const getStartedContent = {
  title: "So easy to setup, a child could do it.",
  description: (
    <>
      Seriously, though. We gave a fourteen year old nothing but a Billsby
      login, a half-finished website and our{" "}
      <a
        href="https://support.billsby.com/docs"
        target="_blank"
        rel="noopener noreferrer"
      >
        industry leading documentation
      </a>
      . Then, we gave a professional website designer a Chargebee login. Here's
      how it went.
    </>
  ),
  url: "https://app.billsby.com/registration",
  buttonColor: "orange",
  buttonText: "Get started with your account today",
  isExternal: true,
  image: "video-thumbanail.png",
  imageName: "video",
  videoDetails: (
    <>
      <FontAwesomeIcon icon={faPlay} className="icon-play" /> Billsby vs.
      Chargebee - Getting Started (8:37)
    </>
  ),
}
